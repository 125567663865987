.sidebar {
    position: relative;
    height: 100%;
    display: block;
    margin-top: 1rem;

    @include media-breakpoint-up(lg){
        margin-top: 0;
    }

    &__title {
        font-size: 1.25em;
        position: relative;
        padding-bottom: 1.42rem;
        margin-bottom: 1.25rem;
        
        @include media-breakpoint-up(sm){
            font-size: 1.5em;
        }
        
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, var(--theme-color-green) 0%, var(--theme-color-blue) 100%);
            opacity: .8;
            z-index: 1;
        }
    }

    ul {
        margin-bottom: 0;
    }
}
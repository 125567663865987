.content {
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
	}
	
	a {
		color: var(--theme-color-green-white);
		transition: color .2s ease-in-out;

		&:hover,
		&:focus-visible {
			color: var(--theme-color-black-contrast) !important;
		}
	}

	ul {
		line-height: 1.4;
		padding-left: 1rem;
		
		li {
			list-style-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.65039 3.80005L7.98372 10.1334L1.65039 16.4667' stroke='%233D3D3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			padding-bottom: .6rem;
			padding-left: .125rem;
			
			.wcag-active &{
				list-style-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.65039 3.80005L7.98372 10.1334L1.65039 16.4667' stroke='%23FFFF00' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			}

			a {
				color: var(--theme-color-green-white);
				transition: color .25s ease-in-out;

				&.active,
				&:hover,
				&:focus-visible{
					color: var(--theme-color-black-contrast) !important;
				}
			}
		}

		ul {
			padding-top: 1rem;
		}
	}

	figure {

		img {
			max-width: 100%;
			height: auto;
			border-radius: $theme-radius/2
		}

		figcaption {
			font-size: .875rem;
			text-align: center;
			padding-top: 0.25rem;
		}
	}

	&__thumb {
		height: 220px;
		margin-bottom: 2rem;

		img {
			width: 100%;
			height: 100%;
			border-radius: $theme-radius*5 0 $theme-radius*5 0;
			object-fit: cover;
			object-position: center;
		}

		@include media-breakpoint-up(sm){
			height: 320px;
		}
		@include media-breakpoint-up(md){
			height: 420px;
		}
	}
}

.gallery {
	display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    margin: 1.5rem 0;

	@include media-breakpoint-up(md){
		grid-template-columns: repeat(3, 1fr);
	}

	&__img {
		position: relative;
		display: block;
		max-width: 100%;
		height: 160px;
		z-index: 9;
		
		@include media-breakpoint-up(sm){
			height: 220px;
		}

		img {
			width: 100%;
			height: 100%;
			border-radius: $theme-radius/4;
			object-fit: cover;
			object-position: center;
		}
	}

	&__desc {
		position: absolute;
		font-size: .75rem;
		text-align: center;
		bottom: 0;
		width: 100%;
		padding: .5rem .75rem;
		background-color: rgba(36, 36, 36, 0.7);
		border-radius: 0 0 $theme-radius/4 $theme-radius/4;
		color: var(--theme-color-white) !important;

		@include media-breakpoint-up(sm){
			font-size: .875rem;
		}
	}
}
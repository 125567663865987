.btn {
    position: relative;
    display: inline-block;
    padding: .65rem 1.75rem;
    border-radius: $theme-radius*2 0 $theme-radius*2 0;
    border: none;
    color: var(--theme-color-white-black);
    background-color: var(--theme-color-green);
    overflow: hidden;
    z-index: 4;
    transition: background-color .25s ease-in-out, color .25s ease-in-out;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--theme-color-blue-black);
        transform: translateY(104%);
        transition: transform .2s cubic-bezier(0.25, 0.46, 0.45, 1);
        z-index: -1;
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
        color: var(--theme-color-white);
        background-color: var(--theme-color-blue);

        &::before {
            transform: translateY(0);
        }
    }
}
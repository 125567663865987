html {
    --wcag-white-color: #fff;
    --wcag-black-color: #0a0a0a;
    --wcag-yellow-color: #ff0;
    --wcag-red-color: #d30722;

    &.wcag-active {
        --theme-color-black: var(--wcag-black-color);
        --theme-color-white: var(--wcag-yellow-color);
        --theme-color-blue: var(--wcag-yellow-color);
        --theme-color-green: var(--wcag-yellow-color);
        --theme-color-gray: var(--wcag-yellow-color);
        --theme-color-snow: var(--wcag-black-color);

        --theme-color-outline: var(--wcag-red-color);

        --theme-color-black-contrast: var(--wcag-yellow-color);
        --theme-color-black-white: var(--wcag-white-color);
        --theme-color-white-black: var(--wcag-black-color);
        --theme-color-blue-white: var(--wcag-white-color);
        --theme-color-blue-black: var(--wcag-black-color);
        --theme-color-green-white: var(--wcag-white-color);
    }
}
.section {
    padding: 2rem 0 3rem;

    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
    }

    &--decor {
        @include media-breakpoint-up(xl){
            background-image: url('./../img/svg/section-decor.svg');
            background-position: calc((100% - 1320px)/2 - 427px) 2rem;
            background-repeat: no-repeat;
    
            .wcag-active & {
                background-image: none;
            }
        }
    }

    &--news {
        padding: 2rem 0 1rem;
    }
}
.home-cta {
    position: relative;
    padding: 10rem 0 2rem;
    margin: 0 0 4rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;
    z-index: 9;
    
    @include media-breakpoint-up(sm){
        padding: 8rem 0 2rem;
    }
    @include media-breakpoint-up(lg){
        padding: 2.5rem 0;
    }
    @include media-breakpoint-up(xl){
        margin: 2rem 0 4rem;
    }

    &::before {
        content: url('./../img/svg/cta-decor.svg');
        position: absolute;
        display: none;
        top: 0;
        bottom: 0;
        left: calc((100% - 1320px)/2 - 350px);
        opacity: .95;
        z-index: -1;
        
        @include media-breakpoint-up(lg){
            display: block;
        }
    }

    .wcag-active & {
        background-image: none !important;
        border-top: 1px solid var(--theme-color-white);
        border-bottom: 1px solid var(--theme-color-white);
    }

    &__body {
        text-align: center;
        padding: 1.75rem 1rem 1rem;
        border-radius: $theme-radius*3 0 $theme-radius*3 0;
        background-color: rgba(255, 255, 255, 0.96);
        
        @include media-breakpoint-up(sm){
            padding: 2rem 1rem;
        }
        @include media-breakpoint-up(lg){
            text-align: left;
            padding: 0;
            background-color: transparent;
            border-radius: 0;
        }
    }

    &__desc {
        font-size: 1.125em;
        line-height: 1.2;
        color: var(--theme-color-green);
        margin: 1rem 0 1.75rem;
        
        @include media-breakpoint-up(sm){
            font-size: 1.25em;
        }
    }

    &__title,
    &__desc {
        .wcag-active &{
            color: var(--theme-color-white-black);
        }
    }
}
button {
    border: none;
    background-color: transparent;
}

table {
    @extend .table;
    @extend .table-responsive;

    thead {
        color: var(--theme-color-blue);
        
        tr {
            th {
                padding: .65rem .5rem;
                font-weight: 400;
                border: 0;
                box-shadow: 0;
                background-color: var(--theme-color-blue);
            }
        }
    }

    tbody {
        tr {
            &:nth-child(even){
                td {
                    background-color: var(--theme-color-snow);
                }
            }

            td {
                border: 0;
                color: var(--theme-color-black-contrast) !important;
                box-shadow: none;

                a {
                    color: var(--theme-color-blue-white);
                    transition: color .2s ease-in-out;

                    &:hover,
                    &:focus-visible {
                        color: var(--theme-color-black-contrast) !important;
                    }
	            }
            }
        }
    }

    &.table--space {
        tbody {
            tr {
                td {
                    padding: .85rem .5rem;
                }
            }
        }
    }
}

// Bootstrap
.form-control {
    min-height: 42px;
    border-radius: $theme-radius/4;
    border: 1px solid var(--theme-color-gray);
    background-color: transparent;

    &:focus {
        box-shadow: none;
        border-color: var(--theme-color-green-white);
        background-color: transparent;
    }
}

// Wordpress
.alignleft, img.alignleft {
	margin-right: 1.5em;
    float: left;
    max-width: 100%;
    height: auto;
}

.alignright, img.alignright {
	margin-left: 1.5em;
    float: right;
    max-width: 100%;
    height: auto;
}

.aligncenter, img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
    clear: both;
    max-width: 100%;
    height: auto;
}

.alignnone, img.alignnone {
    max-width: 100%;
    height: auto;
}

// Contact Form
.wpcf7 {
    .col-cf7 {
        margin-bottom: 1.75rem;

        p {
            margin-bottom: 0;
        }
    }
    
    .wpcf7-form-control {
        &.wpcf7-not-valid {
            border-color: #dc3545;
        }
    }
    .wpcf7-textarea {
        min-height: 200px;
    }
    
    .wpcf7-not-valid-tip {
        font-size: 12px;
        margin-top: .25rem;
        text-align: center;
        color: #dc3545;
    }
    
    .wpcf7-response-output {
        font-size: 14px;
        text-align: center;
        margin: 2em auto 1em !important;
        padding: 0.65rem 1rem !important;
        line-height: 1.2;
        border: none !important;
        border-radius: $theme-radius/4;
        color: var(--theme-color-black);
        background-color: var(--theme-color-snow);
        max-width: calc(100% - 1.5rem);
    }

    form.sent {
        .wpcf7-response-output {
            color: var(--theme-color-white);
            background-color: var(--theme-color-green);
        }
    }

    .wpcf7-submit {
        &:disabled {
            opacity: .7;
        }
    }
    
    .wpcf7-spinner {
        display: none;
    }
    
    .wpcf7-acceptance {
        color: var(--theme-color-black);
    
        .wpcf7-list-item {
            font-size: 14px;
            padding-top: .15rem;
            margin-left: 0 !important;
    
            input[type="checkbox"] + span {
                position: absolute;
                cursor: pointer;
                width: 16px;
                height: 16px;
                left: 0;
                border: 12px solid var(--theme-color-black);
                background-color: var(--theme-color-black);
                border-radius: 2px;
                top: 0;
                transition: all .3s ease-out;
                text-indent: 24px;
                white-space: nowrap;
                line-height: 0px;
                user-select: none;
    
                &:after {
                    content: "";
                    position: absolute;
                    width: 0px;
                    height: 7px;
                    border-bottom: 2px solid #fff;
                    border-left: 2px solid #fff;
                    top: 22%;
                    left: -2px;
                    transform-origin: bottom left;
                    transform: rotate(-45deg);
                    opacity: 0;
                    transition: all .3s ease-out;
                }
            }
    
            input[type="checkbox"]:checked + span {
                border: 12px solid #5a5a5a;
                background-color: #5a5a5a;
    
                &:after {
                    opacity: 1;
                    width: 13px;
                }
            }
    
            input {
                width: 16px;
                height: 16px;
                position: relative;
                top: 3px;
                margin-right: .5rem;
            }
        }
    }

}

// Lightbox
.lb-closeContainer {
    display: none;
}
hr {
    position: relative;
    border: 0;
    height: 1px;
    z-index: 4;

    @include media-breakpoint-up(lg){
        margin: 1.25rem 0;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, var(--theme-color-green) 0%, var(--theme-color-blue) 100%);
        z-index: 1;
    }
}
html {
    --theme-black: #3b3b3b;
    --theme-white: #FFFFFF;

    --theme-color-black: #3b3b3b;
    --theme-color-white: #FFFFFF;
    --theme-color-blue: #2F4198;
    --theme-color-green: #61AB32;
    --theme-color-gray: #e0e0e0;
    --theme-color-snow: #f9f9f9;

    --theme-color-outline: var(--theme-color-black);

    --theme-color-black-contrast: var(--theme-color-black);
    --theme-color-black-white: var(--theme-color-black);
    --theme-color-white-black: var(--theme-color-white);
    --theme-color-blue-white: var(--theme-color-blue);
    --theme-color-blue-black: var(--theme-color-blue);
    --theme-color-green-white: var(--theme-color-green);
}
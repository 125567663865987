.card-news {
    display: block;
    margin-bottom: 2.75rem;

    &__thumb {
        display: block;
        width: 100%;
        height: 200px;
        overflow: hidden;
        margin-bottom: 1.25rem;
        transition: transform .5s cubic-bezier(0.215, 0.610, 0.355, 1);
        border-bottom: 4px solid var(--theme-color-navy);
        border-radius: $theme-radius*5 0 $theme-radius*5 0;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transform: scale(1);
            transition: transform .5s cubic-bezier(0.215, 0.610, 0.355, 1);
            
            &:hover {
                transform: scale(1.15);
            }
        }

        @include media-breakpoint-up(sm){
            height: 380px;
        }
    }

    &__body {
        padding: 0 .5rem;
    }

    &__title {
        display: -webkit-box;
        font-size: 1.125em;
        font-weight: 400;
        color: var(--theme-color-blue);
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        
        @include media-breakpoint-up(md){
            font-size: 1.375em;
            min-height: 55px;
        }
    }

    &__date {
        color: var(--theme-color-blue);
    }

    &__desc {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        text-overflow: ellipsis;
        margin-top: 0.5rem;
        overflow: hidden;
    }

    &__link {
        @extend .btn;
        color: var(--theme-color-blue);
        transition: color .2s ease-in-out;
        
        &:hover,
        &:focus-visible {
            color: var(--theme-color-blue-white);
        }
    }
    
    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1.25rem;
        gap: 1rem;
    }

    &--small &__thumb {
        @include media-breakpoint-up(sm){
            height: 320px;
        }
    }

    &--small &__title {
        min-height: initial;
        font-size: 1.25em;
    }
}
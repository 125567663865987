.module-hero {
    padding-bottom: 2rem;
    overflow: hidden;
    
    @include media-breakpoint-up(md){
        padding-bottom: 5rem;
    }

    .wcag-active & {
        border-top: 1px solid var(--theme-color-blue);
    }

    &__wrapper {
        position: relative;
        width: 100%;

        &::before {
            content: url('./../img/content/theme-decor.png');
            display: none;
            position: absolute;
            top: 130px;
            bottom: 0;
            left: -160px;
            opacity: .1;

            .wcag-active & {
                display: none;
            }
        }

        @include media-breakpoint-up(md){
            height: 550px;
            background-color: var(--theme-color-navy);
        }
        @include media-breakpoint-up(xxl){
            margin-left: auto;
            width: calc(100% - ((100% - 1320px)/2) - .75rem);

            &::before {
                display: block;
            }
        }
    }

    &__body {
        position: relative;
        z-index: 5;
        overflow: hidden;

        img {
            height: 280px;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
        
        @include media-breakpoint-up(md){
            display: flex;
            align-items: flex-end;
            height: 550px;

            img {
                height: inherit;
            }
        }
        @include media-breakpoint-up(xxl){
            border-radius: $theme-radius*3 0 0 $theme-radius*3;
        }
    }

    &__title {
        @extend .h3;
        margin-bottom: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;

        @include media-breakpoint-down(md){
            font-size: 1.5em;
        }
    }

    &__content {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        gap: 1.75rem;
        width: 100%;
        padding: 1.5rem 1rem 1.75rem;
        z-index: 6;
        
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--theme-color-white-black);
            z-index: -1;
        }

        @include media-breakpoint-up(md){
            position: absolute;
            text-align: left;
            gap: 1rem;
            flex-direction: row;
            height: 100px;
            padding: 1.5rem 2.75rem;

            &::before {
                opacity: .9;
            }
        }
        @include media-breakpoint-up(xxl){
            padding: 1.5rem 3rem 1.5rem 2rem;
            width: calc(100% - (100% - 1320px) - 1.5rem); 

            &::before {
                border-top-right-radius: $theme-radius*3;
            }
        }
    }

    &__buttons {
        flex-shrink: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }
}
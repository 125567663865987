.skip-links {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 9999;

    a {
        position: absolute;
        top: 0;
        display: block;
        font-size: 14px;
        line-height: 1.2;
        left: -9000em;
        text-align: center;
        padding: .75rem;
        background-color: var(--theme-color-black);
        color: var(--theme-color-white) !important;
        width: 220px;
        text-decoration: none;
        opacity: 0;
        transition: opacity 0.3s ease 0s;

        &:hover,
        &:focus,
        &:active,
        &:focus-visible,
        &:visited {
            left: 0;
            opacity: 1; 
            z-index: 99;
            color: var(--theme-color-white) !important;
        }
    }
}
.header {
    position: relative;
    background-color: var(--theme-color-white-black);
    z-index: 9;

    @include media-breakpoint-down(lg){
        position: sticky;
        height: initial;
        top: 0;
        box-shadow: 4px 0px 14px 7px rgba(27, 35, 82, 0.1);
        z-index: 999;

        .wcag-active & {
            border-bottom: 1px solid var(--theme-color-blue)
        }
    }

    &__wrapper {
        @extend .container-xxl;
    }

    &__prenav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .75rem 0;
        
        @include media-breakpoint-up(lg){
            padding: 1rem 0 0;
        }
    }

    &__nav {
        width: 100%;
        background-color: var(--theme-color-white-black);
        transition: box-shadow .2s ease-in-out, transform .35s cubic-bezier(0.39, 0.575, 0.565, 1);
        
        @include media-breakpoint-down(lg){
            position: fixed;
            height: 0;
            overflow: auto;
            z-index: 9999;
        }
        @include media-breakpoint-up(lg){
            position: sticky;
            top: 0;
            z-index: 999;

            &--scroll {
                box-shadow: 4px 0px 14px 7px rgba(27, 35, 82, 0.1);
            }
        }
    }

    &__logo {
        max-height: 70px;
    
        .wcag-active &{
            filter: brightness(0) invert(1) !important;
        }
        
        @include media-breakpoint-up(lg){
            max-height: 100px;
        }
        @include media-breakpoint-up(xxl){
            max-height: 120px;
        }
    }

    &__toggler {
        display: block;
        float: right;

        @include media-breakpoint-up(lg){
            display: none;
        }

        .line {
            fill: none !important;
            stroke: var(--theme-color-black-contrast);
            stroke-width: 6;
            transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
            stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
        }
        .line1 {
            stroke-dasharray: 60 207;
        }
        .line2 {
            stroke-dasharray: 60 60;
        }
        .line3 {
            stroke-dasharray: 60 207;
        }
        &:not(.collapsed) .line1 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
        }
        &:not(.collapsed) .line2 {
            stroke-dasharray: 1 60;
            stroke-dashoffset: -30;
        }
        &:not(.collapsed) .line3 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
        }
    }
    
    &__info {
        display: none;
        position: relative;
        flex-grow: 1;
        gap: .875rem;
        justify-content: space-between;
        align-items: center;

        &--mobile {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: .75rem;
            padding: 1rem;
            background-color: var(--theme-color-snow);
            margin-bottom: 0.75rem;
        }
        
        &::before {
            content: '';
            position: absolute;
            display: none;
            bottom: -.75rem;
            right: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, var(--theme-color-green) 0%, var(--theme-color-blue) 100%);
            opacity: .8;
            z-index: -1;
        }

        @include media-breakpoint-up(lg){
            display: flex;
            margin-left: 2rem;

            &--mobile {
                display: none;
            }
            
            &::before {
                display: block;
            }
        }
        @include media-breakpoint-up(xl){
            gap: 2rem;
            margin-left: 4rem;
            
            &::before {
                bottom: -1.5rem;
            }
        }
        @include media-breakpoint-up(xxl){
            margin-left: 0;
            justify-content: flex-end;

            &::before {
                bottom: -2rem;
                width: 94%;
            }
        }
    }

    &__link {
        font-size: 0.875em;
        font-weight: 500;
        color: var(--theme-color-green);

        @include media-breakpoint-down(lg){
            width: 100%;
            justify-content: center;
            text-align: center;
        }

        a {
            display: inline-flex;
            align-items: center;
            color: var(--theme-color-blue);
        }
        
        span {
            &.__icon {
                display: inline-block;
                margin-right: 0.25rem;

                &:not(.__icon--default){
                    path {
                        fill: var(--theme-color-green);
                    }
                }

                .wcag-active &{
                    path {
                        fill: var(--theme-color-green);
                    }
                }
            }
        }
    }

    &__search {
        transform-origin: bottom;
        transition: transform .5s cubic-bezier(0.175, 0.885, 0.32, 1);
        
        @include media-breakpoint-up(xxl){
            margin: 0 1rem;
        }

        button {
            path {
                fill: var(--theme-color-green);
                transition: fill .3s ease-in-out;
            }
    
            &:hover,
            &:focus-visible {
                path {
                    fill: var(--theme-color-blue-white);
                }
            }
        }
    }

    a {
        transition: color .25s ease-in-out;

        &:hover,
        &:focus-visible {
            color: var(--theme-color-green-white);
        }
    }
}
.breadcrumbs {
    margin: 1rem 0 0;
    
    @include media-breakpoint-up(md){
        margin: 2.5rem 0 0;
    }

    &__wrapper {
        @extend .container-xxl;
    }

    &__content {
        @extend .breadcrumb;
        font-size: .875em;
        flex-wrap: nowrap;
        overflow: auto;
        margin-bottom: 0;
        
        @include media-breakpoint-up(sm){
            font-size: 1em;
        }
    }
    
    &__item {
        @extend .breadcrumb-item;
        display: inline;
        flex: 0 0 auto;
        padding-top: 0.5rem;
        padding-bottom: 0.15rem;
        text-overflow: ellipsis;
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        
        @include media-breakpoint-up(md){
            flex: 0 1 auto;
        }
        
        a {
            transition: color .25s ease-in-out;

            &:hover,
            &:focus,
            &:focus-visible {
                color: var(--theme-color-blue-white);
            }
        }

        span {
            font-weight: 600;
        }
    }
}
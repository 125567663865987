.footer {
    &__wrapper {
        position: relative;
        padding: 3rem 0 0;
        color: var(--theme-color-white);
        background-color: var(--theme-color-blue-black);
        overflow: hidden;
        z-index: 9;
        
        @include media-breakpoint-up(lg){
            padding: 3rem 0;
        }
        
        &::before {
            content: url('./../img/svg/footer-decor.svg');
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            right: calc((100% - 960px)/2 - 550px);
            z-index: -1;
            
            @include media-breakpoint-up(lg){
                display: block;
            }
            @include media-breakpoint-up(xl){
                right: calc((100% - 1140px)/2 - 550px);
            }
            @include media-breakpoint-up(xxl){
                right: calc((100% - 1320px)/2 - 480px);
            }
        }
        
        .wcag-active &{
            border-top: 1px solid var(--theme-color-blue);
            border-bottom: 1px solid var(--theme-color-blue);
            background-image: none;

            &::before {
                display: none;
            }
        }
    }


    &__title {
        font-size: 1.25em;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    &__logo {
        display: none;
        position: absolute;
        top: 5rem;
        left: 1rem;
        margin-bottom: 1.5rem;
        max-height: 120px;

        @include media-breakpoint-up(lg){
            display: block;
        }
    }

    &__info {
        font-weight: 300;
        
        @include media-breakpoint-up(lg){
            color: var(--theme-color-black-contrast);
        }
    }

    &__links {
        margin-top: 1rem;
        margin-bottom: 2rem;

        .header__link {
            margin-bottom: 0.35rem;
            text-align: right;

            @include media-breakpoint-down(lg){
                text-align: center;

                a {
                    color: var(--theme-color-white);
                }

                path {
                    fill: var(--theme-color-white) !important;
                }
            }

            a {
                transition: color .25s ease-in-out;

                &:hover,
                &:focus-visible {
                    color: var(--theme-color-green-white);
                }
            }
        }
    }

    &__copyrights {
        padding: 2.5rem 0;
        text-align: center;
        border-top: 1px solid #b3b3b3;
        color: var(--theme-color-white);
        
        @include media-breakpoint-up(lg){
            text-align: right;
            color: var(--theme-color-black-contrast);
        }

        a {
            transition: color .25s ease-in-out;

            &:hover,
            &:focus-visible {
                color: var(--theme-color-green-white);
            }
        }
    }

    &__developer {
        font-size: .875rem;
        margin-top: 1rem;
        
        @include media-breakpoint-up(lg){
            margin-top: 0;
            color: var(--theme-color-green);
        }
    }

    ul {
        list-style: none;
        padding: 0;

        a {
            transition: color .2s ease-in-out;

            &:hover,
            &:focus-visible {
                color: var(--theme-color-green-white);
            }
        }
    }
}
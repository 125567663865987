.search-box {
    position: fixed;
    display: none;
    width: 100%;
    top: 0;
    left: 0;
    padding: 1rem 0;
    color: var(--theme-color-white);
    background-color: var(--theme-color-blue-black);
    z-index: 99999;
    
    @include media-breakpoint-up(sm){
        padding: 1rem;
    }

    &.show {
        display: block;
    }

    .wcag-active &{
        border-bottom: 1px solid var(--theme-color-blue);
    }

    input {
        color: var(--theme-color-white);
        background-color: transparent;
        border: none;

        &::placeholder {
           color: var(--theme-color-white); 
        }
        
        &:active,
        &:focus {
            color: var(--theme-color-white);
            background-color: transparent;
        }
    }

    .submit {
        color: inherit;
        height: 40px;
        line-height: 50px;
        border: none;
        background: transparent;

        &:hover,
        &:focus,
        &:focus-visible {
            color: var(--theme-color-white) !important;
            outline: 3px dotted var(--theme-color-white);
        }
    }
}

.search-result {
    display: block;
    border-radius: $theme-radius/2;
    padding: 1rem .75rem;
    height: calc(100% - 2rem);
    margin-bottom: 2rem;
    background-color: var(--theme-color-white-black);
    transition: background-color .25s ease-in-out, color .25s ease-in-out;

    &__title {
        font-size: 1.125em;
        line-height: 1.2;
        font-weight: 500;
        margin-bottom: 0.5rem;
        display: -webkit-box;
        box-orient: vertical;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
	
    &:hover,
    &:focus-visible {
        color: var(--theme-color-green-white) !important;
        background-color: var(--theme-color-snow);

        .wcag-active & {
            background-color: transparent;
        }
    }
}
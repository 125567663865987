h1 { font-size: 1.65em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.375em; }
h4 { font-size: 1.25em; }
h5 { font-size: 1.125em; }
h6 { font-size: 1em; }

@media screen and (min-width: 1200px) {
  h1 { font-size: 1.75em; }
  h2 { font-size: 1.65em; }
  h3 { font-size: 1.5em; }
}

a,
a:hover {
    color: unset;
    text-decoration: none;
}

.wcag-active {
    a {
        text-decoration: underline;

        &:hover {
            color: var(--theme-color-white);
        }
    }
    
    a,
    button {
        &:hover,
        &:focus-visible {
            text-decoration: none;
        }
    }
}
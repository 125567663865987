html {
	scroll-behavior: smooth;
}

body {
	position: relative;
	font-size: 1em;
	height: 100%;
	color: var(--theme-color-black-contrast);
	background-color: var(--theme-color-white-black);
	line-height: 1.65;
	font-family: $font-prime;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.size--small {
		font-size: 14px;
	} 
	&.size--normal {
		font-size: 16px;
	}
	&.size--big {
		font-size: 18px;
	}
}

.container-xxl {
	@include media-breakpoint-between(sm,xxl){
		padding-left: 2.75rem;
		padding-right: 2.75rem;
	}
}

:focus-visible {
    outline: 3px dotted var(--theme-color-outline);

    .wcag-active & {
        color: var(--wcag-white-color);
    }
}

.wcag-active {
	img {
		filter: grayscale(1);
	}
}
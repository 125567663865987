.titlebar {
    position: relative;
    overflow: hidden;
    margin-bottom: 1.5rem;
    z-index: 9;

    .wcag-active & {
        border-top: 1px solid var(--theme-color-blue);
        border-bottom: 1px solid var(--theme-color-blue);
    }

    @include media-breakpoint-up(lg){
        margin-bottom: 2.5rem;
    }

    &__wrapper {
        display: flex;
        position: relative;
        height: 200px;
        width: 100%;
        z-index: 10;

        &::before {
            content: '';
            background-image: url('./../img/content/theme-decor.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            display: none;
            position: absolute;
            width: 418px;
            height: 280px;
            top: 0;
            bottom: 0;
            margin: auto;
            left: -160px;
            opacity: .1;
            z-index: -1;

            .wcag-active & {
                display: none;
            }
        }

        @include media-breakpoint-up(md){
            height: 300px;
        }

        @include media-breakpoint-up(lg){
            margin-left: auto;
            width: calc(100% - 2.75rem);

        }
        
        @include media-breakpoint-up(lg){
            &::before {
                display: block;
            }
        }

        @include media-breakpoint-up(xxl){
            width: calc(100% - ((100% - 1320px)/2) - .75rem);
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;

        @include media-breakpoint-up(lg){
            border-radius: $theme-radius*3 0 0 $theme-radius*3;
        }

        .wcag-active & {
            filter: grayscale(1);
        }
    }
}
.diagnosis-info {
    &__img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        object-position: center;
        border-radius: $theme-radius*3 0 $theme-radius*3 0;
        
        @include media-breakpoint-up(md){
            height: 420px;
        }
    }
}
.accordion {
    border: 0;
    margin-bottom: 1.75rem;

    &__item {
        &:not(:last-of-type()){
            border-bottom: 1px solid var(--theme-color-gray);
        }

        .accordion-header {
            margin: 0;
        }

        .accordion-button {
            font-size: .875em;
            padding: 1rem .25rem;
            gap: 0.25rem;
            background-color: transparent;
            transition: color .25s ease-in-out;
            color: var(--theme-color-black-contrast);
            box-shadow: none;

            @include media-breakpoint-up(md){
                font-size: 1em;
            }

            &::after {
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233D3D3D'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
                
                .wcag-active & {
                    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff0'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
                }
            }

            &:not(.collapsed){
                color: var(--theme-color-blue-white);

                &::after {
                    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230096D0'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
                    
                    .wcag-active & {
                        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");                        
                    }
                }
            }

            &:hover,
            &:focus-visible {
                color: var(--theme-color-blue-white);
            }

            &:focus-visible {
                outline: 3px dotted var(--theme-color-outline);
            }
        }

        .accordion-body {
            padding: .5rem 0 1rem;

            a {
                font-weight: 500;
                color: var(--theme-color-blue);
                transition: color .25s ease-in-out;
                
                &:hover,
                &:focus-visible {
                    color: var(--theme-color-blue-white);
                }
            }
        }

        .accordion-file {
            font-size: .875rem;
            padding: .65rem .5rem;

            &:nth-child(odd){
                background-color: var(--theme-color-snow);
            }

            a {
                display: flex;
                line-height: 1.4;
                color: var(--theme-color-black-contrast);
                gap: 1rem;
                justify-content: space-between;
                transition: color .25s ease-in-out;

                div {
                    font-weight: 500;
                    
                    &:nth-child(2){
                        font-weight: 400;
                        white-space: nowrap;
                        text-transform: uppercase;
                    }
                }

                &:hover,
                &:focus-visible {
                    color: var(--theme-color-green-white) !important;
                }
            }
        }
    }
}
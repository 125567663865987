.pagination {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0;
    justify-content: center;
    margin-bottom: 0.5rem;

    @include media-breakpoint-up(sm){
        justify-content: flex-start;
    }

    .page-item {
        .page-link {
            display: block;
            font-weight: 400;
            border-radius: $theme-radius 0 $theme-radius 0;
            border: none;
            line-height: 2.4rem;
            text-align: center;
            height: 2.5rem;
            width: 2.5rem;
            background-color: var(--theme-color-blue);
            color: var(--theme-color-white-black);
            transition: color .2s ease-in-out, background-color .2s ease-in-out;

            path {
                fill: var(--theme-color-white-black);
            }

            &:hover,
            &:focus-visible {
                color: var(--theme-color-white-black) !important;
                background-color: var(--theme-color-green-white);
            }
        }

        &.active {
            .page-link {
                background-color: var(--theme-color-blue-white);
                color: var(--theme-color-white-black);
                
                &:hover,
                &:focus-visible {
                    color: var(--theme-color-white-black) !important;
                }
            }
        }
    }
}
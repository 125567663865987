.card-offer {
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 2.75rem;
    border-radius: $theme-radius*5 0 $theme-radius*5 0;

    .wcag-active & {
        border: 1px solid var(--theme-color-white);
    }

    &__thumb {
        width: 100%;
        height: 240px;
        object-fit: cover;
        object-position: center;
        transform: scale(1);
        transition: transform .5s cubic-bezier(0.215, 0.610, 0.355, 1);
        
        @include media-breakpoint-up(sm){
            height: 300px;
        }
    }

    &__body {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        padding: 1rem;
        width: 100%;
        height: 80px;
        background-color: rgba(255, 255, 255, 0.85);
        
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, var(--theme-color-green) 0%, var(--theme-color-blue) 100%);
            z-index: 1;
        }
        
        .wcag-active & {
            background-color: rgba(0, 0, 0, 0.85);
        }
        
        @include media-breakpoint-up(sm){
            height: 100px;
            padding: 1rem 1.25rem;
        }
    }

    &__title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 1em;
        font-weight: 500;
        margin-bottom: 0;
        color: var(--theme-color-blue);
        
        @include media-breakpoint-up(sm){
            font-size: 1.25em;
        }
    }

    &:hover,
    &:focus-visible {
        .card-offer__thumb {
            transform: scale(1.15);
        }
    }
}
.navbar {
    padding: 1rem 0;
    transition: all .4s ease-in-out;
    
    @include media-breakpoint-up(lg){
        padding: 2rem 0;
    }

    &__main {
        display: block;
        width: 100%;
        padding-bottom: 100px;
        color: var(--theme-color-blue);
        
        @include media-breakpoint-up(lg){
            padding-bottom: 0;
        }

        ul {
            list-style: none;
            line-height: 1.6;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0;
            margin: 0;
            
            @include media-breakpoint-up(lg){
                flex-direction: row;
                line-height: 1.2;
            }

            li {
                &.navbar__item {
                    text-align: center;

                    @include media-breakpoint-down(lg){
                        padding: .65rem 0;

                        &:not(:last-of-type){
                            border-bottom: 1px solid var(--theme-color-gray);
                        }
                    }
                }
                .navbar__link {
                    font-weight: 500;
                    padding: .25rem 0;
                    transition: color .2s ease-in-out;

                    &.show,
                    &:hover,
                    &:focus-visible {
                        color: var(--theme-color-green-white);
                    }

                    &--special {
                        color: var(--theme-color-blue);
                    }
                    
                    @include media-breakpoint-up(lg){
                        padding: .25rem 1.5rem;

                        &:first-of-type{
                            padding-left: .5rem;
                        }
                        &:last-of-type{
                            padding-right: .5rem;
                        }
                    }

                    @include media-breakpoint-between(lg,xl){
                        font-size: .875em;
                    }
                }

                &:nth-last-child(1){
                    .navbar__link {
                        color: var(--theme-color-green);

                        &:hover,
                        &:focus-visible {
                            color: var(--theme-color-blue-white);
                        }
                    }
                }

                &.dropdown {
                    .dropdown-toggle {
                        &::after {
                            float: right;
                            border: solid var(--theme-color-blue);
                            border-width: 0 2px 2px 0;
                            display: none;
                            margin-top: .35rem;
                            padding: 4px;
                            transform: rotate(45deg);
                            opacity: .3;
                            transition: transform .5s cubic-bezier(0.165, 0.84, 0.44, 1);
                            
                            @include media-breakpoint-up(lg){
                                display: none;
                                padding: 3px;
                                float: none;
                            }
                        }
                    }

                    .dropdown-menu {
                        display: none;
                        padding: .75rem 0;
                        border: 0;
                        margin-top: .5rem; 
                        background: var(--theme-color-white-black);
                        box-shadow: 0px 12px 14px -4px rgba(61, 61, 61, 0.1);

                        @include media-breakpoint-down(lg){
                            position: relative;
                            box-shadow: none;
                            text-align: center;
                            margin-top: 0;
                        }

                        &.show {
                            display: block;
                        }
                        
                        .dropdown-item {
                            font-weight: 500;
                            color: var(--theme-color-blue);
                            transition: color .25s ease-in-out;

                            &:focus,
                            &:active,
                            &.active,
                            &:hover,
                            &:focus-visible {
                                color: var(--theme-color-green-white) !important;
                                background-color: transparent !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
.module-latest {
    margin-bottom: 1rem;
    
    @include media-breakpoint-up(xl){
        margin-bottom: 2rem;
    }
    
    &__wrapper {
        position: relative;
        overflow: hidden;
        z-index: 5;

        @include media-breakpoint-up(lg){
            margin: .5rem 0 2rem;
        }
    }
}